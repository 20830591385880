<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">

        <ng-template *ngFor="let slide of slides" carouselSlide>
            <div class="main-banner" [style.background-image]="'url(/' + slide.imageUrl + ')'">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h4>{{slide.caption}}</h4>
                                <span [class]="slide.titleClass" [innerHTML]="slide.title"></span>
                                <p>{{slide.description}}</p>
<!--                                <a href="#about" class="btn btn-primary">Get Started</a>-->
                                <a (click)="onClick(slide.link)" class="btn btn-primary view-work">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </owl-carousel-o>
</div>

<app-about></app-about>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-contact></app-contact>
