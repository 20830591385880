<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Contact <span>Us</span></h2>
            <h3><span class="sub">Kerry Lacey</span></h3>
        </div>
        <div class="row">
<!--            <div class="col-lg-7 col-md-12">-->
<!--                <div class="contact-form">-->
<!--                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">-->
<!--                        <div class="row">-->
<!--                            <div class="col-lg-6 col-md-6">-->
<!--                                <div class="form-group mb-3">-->
<!--                                    <label>Name</label>-->
<!--                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">-->
<!--                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">-->
<!--                                        <div *ngIf="name.errors.required">Name is required.</div>-->
<!--                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>-->
<!--                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-lg-6 col-md-6">-->
<!--                                <div class="form-group mb-3">-->
<!--                                    <label>Email</label>-->
<!--                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">-->
<!--                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-lg-6 col-md-6">-->
<!--                                <div class="form-group mb-3">-->
<!--                                    <label>Subject</label>-->
<!--                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">-->
<!--                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-lg-6 col-md-6">-->
<!--                                <div class="form-group mb-3">-->
<!--                                    <label>Phone Number</label>-->
<!--                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">-->
<!--                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-lg-12 col-md-12">-->
<!--                                <div class="form-group mb-3">-->
<!--                                    <label>Message</label>-->
<!--                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>-->
<!--                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-lg-12 col-md-12">-->
<!--                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-lg-6">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
<!--                                <li><i class="fas fa-map-marker-alt"></i> <span>Location:</span>Exercisplan 4, 111 49 Stockholm, Sweden</li>-->
<!--                                <li><i class="far fa-envelope"></i> <a href="mailto:hello@xavro.com"><span>Email:</span>hello@xavro.com</a></li>-->
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:07813 754212"><span>Call:</span>07813 754212</a></li>
<!--                                <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="/assets/img/garden-images/garden-2.jpg" alt="about" class="rounded-3 fa-border">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
