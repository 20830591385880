<div class="partner-area ptb-70 bg-fffdfd">
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-1.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-2.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-3.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-4.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-5.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-6.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-1.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-2.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-3.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-4.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-5.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-6.png" alt="partner"></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>