<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Work</span></h2>
            <p>Here's a sampling of our garden work:</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ul class="nav-tabset">
                <li *ngFor="let category of categories" class="nav-tab" [ngClass]="{'active': currentCategory === category}">
                    <span (click)="setCategory($event, category)">
                        {{category}}
                    </span>
                </li>
            </ul>

            <div class="photo-grid tabs-container container">
                <div class="single-work" *ngFor="let photo of visiblePhotos">
                    <img [src]="photo.imageUrl" [alt]="photo.alt">
                    <div class="work-content">
                        <a (click)="onClick('contact')"><h4>Creative Landscapes and Gardens</h4></a>
<!--                        <ul>-->
<!--                            <li><a routerLink="/">Contact</a></li>-->
<!--&lt;!&ndash;                            <li> . </li>&ndash;&gt;-->
<!--&lt;!&ndash;                            <li><a routerLink="/">Brand</a></li>&ndash;&gt;-->
<!--                        </ul>-->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
