<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="row">
        <div class="col-lg-6">
            <div class="section-title">
                <h2>About <span>The Kai Garden Company</span></h2>
                <p>Welcome to The Kai Garden Company, your local landscaping experts dedicated to transforming outdoor spaces into breathtaking natural sanctuaries. As a small, family-owned landscaping company, we pride ourselves on our personalized approach and unwavering commitment to quality.

                    Our passionate team of landscape designers, horticulturists, and skilled craftsmen collaborates closely with each client to turn their landscaping dreams into reality. From lush garden designs to pristine lawn care, we offer a range of services that enhance the beauty and functionality of your outdoor environment.</p>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="about-image">
                <img src="/assets/img/kai-garden/garden-01.jpg" alt="about" class="rounded-3 fa-border">
                <div class="video-btn">
                    <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                </div>
            </div>
        </div>
        </div>
        <div class="row align-items-center ml-auto">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2><span>Personal Touch</span></h2>
                        <p>With a keen eye for detail and a genuine love for the natural world, The Kai Garden Company is your trusted partner for all your landscaping needs. We are dedicated to creating outdoor spaces that not only meet your expectations but exceed them. Let us turn your outdoor dreams into a vibrant reality today! Contact us for a free consultation and let's bring your landscaping visions to life.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Landscape Design</li>
                        <li><i class="fas fa-check"></i>Hardscaping</li>
                        <li><i class="fas fa-check"></i>Planting and Gardens</li>
                        <li><i class="fas fa-check"></i>Lawn Care</li>
                        <li><i class="fas fa-check"></i>Maintenance</li>
                    </ul>
<!--                    <p>With a keen eye for detail and a genuine love for the natural world, The Kai Garden Company is your trusted partner for all your landscaping needs. We are dedicated to creating outdoor spaces that not only meet your expectations but exceed them. Let us turn your outdoor dreams into a vibrant reality today! Contact us for a free consultation and let's bring your landscaping visions to life.</p>-->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
